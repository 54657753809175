<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-if="this.$vuetify.breakpoint.name != 'xs'"
        cols="2"
        sm="3"
        lg="2"
        fixed
        class="pa-0 elevation-12"
      >
        <template>
          <TreeView />
        </template>
      </v-col>
      <v-col cols="12" sm="9" lg="10" class="px-8">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TreeView from "@/components/DevTool/TreeView.vue";
export default {
  components: {
    TreeView,
  },
};
</script>
<style></style>
